import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(Logo-rounded.svg);
  background-size: 60vw;
  background-position: 50% 50%;
  height: 100vh;
  background-repeat: no-repeat;
`;

class App extends Component {
  render() {
    return (
      <Container />
    );
  }
}

export default App;
